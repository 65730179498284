import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Helmet from "react-helmet";
import Img from "gatsby-image";

class Spaces extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { data } = this.props;
    const spaces = data.contentfulSpaces;
    return (
      <Layout>
        <Helmet>
          <title>Venue Hire - Gracehouse Church Logan</title>
          <meta
            property="og:title"
            content="Venue Hire - Gracehouse Church Logan"
          />
          <meta
            name="description"
            content="We have several spaces available that can cater for a variety of gatherings whether it is 5, 25 or even 400 people. The facility has a very open, light and modern style after some recent TL&C. Contact us today to book your next event at Gracehouse."
          />
          <meta
            property="og:description"
            content="We have several spaces available that can cater for a variety of gatherings whether it is 5, 25 or even 400 people. The facility has a very open, light and modern style after some recent TL&C. Contact us today to book your next event at Gracehouse."
          />
        </Helmet>
        <section className="spaces-header">
          <div className="container">
            <h1>
              Our <span>Spaces</span>
            </h1>
            <p className="main">{spaces.description.description}</p>
            <div
              className="spaces-header__secondary"
              dangerouslySetInnerHTML={{
                __html: spaces.secondaryText.childMarkdownRemark.html,
              }}
            ></div>
          </div>
        </section>
        <div className="spaces">
          {spaces.spaceList.map((space, index) => (
            <section key={space.id} className="space">
              <div className="container">
                <div className="space__content">
                  <p className="space-number">/0{index + 1}</p>
                  <h2>{space.title}</h2>
                  <p>{space.description}</p>
                  <ul>
                    {space.features.map((feature) => (
                      <li key={feature}>{feature}</li>
                    ))}
                  </ul>
                </div>
                <div className="space__images">
                  {space.images.map((image) => (
                    <Img fluid={image.fluid} className="space-image" />
                  ))}
                </div>
              </div>
            </section>
          ))}
        </div>
        <section className="hire-form">
          <div className="container">
            <h2>Venue Hire</h2>
            <h3>Expression of Interest</h3>
            <div className="hire-form-content">
              <section className="hire-form__form">
                <form
                  name="Venue Hire Expression of Interest"
                  method="POST"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input
                    type="hidden"
                    name="form-name"
                    value="Venue Hire Expression of Interest"
                  />
                  <input
                    type="text"
                    name="fullName"
                    required
                    placeholder="Full Name"
                  />
                  <input
                    type="text"
                    name="dates"
                    required
                    placeholder="Event Date/s"
                  />
                  <input
                    type="text"
                    name="purpose"
                    required
                    placeholder="Event Purpose"
                  />
                  <input
                    type="text"
                    name="guests"
                    required
                    placeholder="No. of Guests"
                  />
                  <textarea
                    name="comments"
                    cols="30"
                    rows="7"
                    placeholder="Additional Comments"
                  ></textarea>
                  <input
                    type="text"
                    name="contactPerson"
                    required
                    placeholder="Contact Person"
                  />
                  <input
                    type="text"
                    name="contactPhone"
                    required
                    placeholder="Contact Phone"
                  />
                  <input
                    type="text"
                    name="contactEmail"
                    required
                    placeholder="Contact Email"
                  />
                  <button type="submit">Express Interest</button>
                </form>
              </section>
              <section className="hire-form__extra">
                <p>Alternatively, you can email us below.</p>
                <a href={`mailto:${spaces.email}`} className="link">
                  {spaces.email}
                </a>
              </section>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Spaces;

export const pageQuery = graphql`
  query SpacesQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulSpaces {
      description {
        description
      }
      secondaryText {
        childMarkdownRemark {
          html
        }
      }
      email
      spaceList {
        description
        features
        id
        title
        images {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
